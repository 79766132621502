import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { css } from "@emotion/react";

export default [
	{
		id: 'loreal-men-expert',
		title: 'L\'oreal Men Expert',
		image: <StaticImage 
			quality={100} 
			aspectRatio={1} 
			src="../../../images/MenExpert3x.png" 
			alt={''} 
			css={css`width: 100%; height: 100%;`}
		/>,
		label: 'Men Expert x Red Bull'
	},
	{
		id: 'garnier',
		title: 'Garnier',
		image: <StaticImage 
			quality={100} 
			aspectRatio={1} 
			src="../../../images/Garnier@3x.png" 
			alt={''} 
			css={css`width: 100%; height: 100%;`}
		/>,
		label: 'Garnier x Moonlight Cinemas'
	},
	{
		id: 'somersby',
		title: 'Somersby',
		image: <StaticImage 
			quality={100} 
			aspectRatio={1} 
			src="../../../images/Somersby@3x.png" 
			alt={''} 
			css={css`width: 100%; height: 100%;`}
		/>,
		label: 'Somersby Shake & Win'
	},
	{
		id: 'loreal-paris',
		title: 'L\'oreal Paris',
		image: <StaticImage 
			quality={100} 
			aspectRatio={1} 
			src="../../../images/LorealParis@3x.png" 
			alt={''} 
			css={css`width: 100%; height: 100%;`}
		/>,
		label: 'L’Oréal Eye Love It'
	},
	{
		id: 'asahi',
		title: 'Asahi',
		image: <StaticImage 
			quality={100} 
			aspectRatio={1} 
			src="../../../images/Asahi@3x.png" 
			alt={''} 
			css={css`width: 100%; height: 100%;`}
		/>,
		label: 'Asahi Staff Rewards'
	},
	{
		id: 'subway',
		title: 'Subway',
		image: <StaticImage 
			quality={100} 
			aspectRatio={1} 
			src="../../../images/Subway@3x.png" 
			alt={''} 
			css={css`width: 100%; height: 100%;`}
		/>,
		label: 'Subway Sink-a-Sub'
	},
	{
		id: 'kellogs',
		title: 'Kellog\'s',
		image: <StaticImage 
			quality={100} 
			aspectRatio={1} 
			src="../../../images/Kellogs@3x.png" 
			alt={''} 
			css={css`width: 100%; height: 100%;`}
		/>,
		label: 'Kellogg’s Back to School'
	},
	{
		id: 'coopers',
		title: 'Coopers',
		image: <StaticImage 
			quality={100} 
			aspectRatio={1} 
			src="../../../images/Coopers@3x.png" 
			alt={''} 
			css={css`width: 100%; height: 100%;`}
		/>,
		label: 'Coopers x Australian Open'
	},
];
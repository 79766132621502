import * as React from 'react'
import { Section } from '../styled'
import Flex from '../../../common/flex'
import MainText from '../../../images/main.svg'
import CursorIcon from '../../../images/click.svg'
import MouseIcon from '../../../images/mouse.svg'
import styled from '@emotion/styled'
import scrollTo from 'gatsby-plugin-smoothscroll'
import Particles from '../../../common/particles'
import { respondTo } from '../../../common/dimensions'
import { isMobile } from 'react-device-detect'

const StyledMainText = styled(MainText)`
	max-width: 100%;
	// width: 500px;
	// svg {
	// 	width: 100%;
	// }
`
const LogoWrapper = styled.div`
	// width: 500px;
	// max-width: 90%;
`
const TextWrapper = styled(Flex)`
	z-index: 1;
	pointer-events: none;
	width: 500px;
	text-align: center;
	max-width: 90%;
	${respondTo.lgUp} {
		svg {
			transform: scale(1.5);
		}
	}
`

const ScrollButtonWrapper = styled.div`
	z-index: 1;
	margin-bottom: 36px;
`

export default function Main() {
	return (
		<Section
			id="main"
			direction="column"
			fullHeight
			justify="flex-end"
			style={{ maxWidth: 'initial' }}
		>
			<Particles id="tsparticles-main" />
			<TextWrapper direction="column" fullHeight align="center" justify="center">
				<LogoWrapper>
					<StyledMainText />
				</LogoWrapper>
			</TextWrapper>
			<ScrollButtonWrapper>
				<button
					onClick={() => {
						scrollTo('#simple')
					}}
				>
					<div className="subtle">Scroll down</div>
					{isMobile ? <CursorIcon /> : <MouseIcon />}
				</button>
			</ScrollButtonWrapper>
		</Section>
	)
}

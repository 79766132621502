import * as React from 'react'
import { Section } from '../styled'
import Particles from '../../../common/particles'
import PageTitle from '../../../common/page-title'
import PageContent from '../../../common/page-content'

export default function Simple() {
	return (
		<Section id="simple" direction="column" fullHeight align="flex-start" justify="center">
			<Particles id="tsparticles-simple" page={2} count={3} />
			<PageTitle superText="We make consumer promotions">Simple.</PageTitle>
			<PageContent>
				Whether you’ve built your own site and simply need to plugin to our instant win API,
				or require a more complete promotion solution, we always make the process nice and
				easy. Our purpose is to lay the foundations for you to achieve your creative vision
				and run a great promo!
			</PageContent>
		</Section>
	)
}

import Flex from '../../common/flex';
import styled from '@emotion/styled';
import { background } from '../../common/palette';
import spacing, { px } from '../../common/spacing';
import { respondTo } from '../../common/dimensions';

const navHeight = '120px';
export const Section = styled(Flex)`
	position: relative;
	min-height: 100vh;
	padding: ${navHeight} 36px 36px;
	background: ${props => props.color ? background[props.color] : 'transparent'};
	width: 100%;
	margin: 0 auto;
	max-width: 1200px;
	${respondTo.xsOnly} {
		padding: ${navHeight} 24px 24px;
	}
`
export const Grid = styled.div`
	display: grid;
	gap: ${({ gap = 'medium' }) => gap ? px(spacing[gap]) : '0px'};
	grid-template-columns: ${({ minWidth = '150px' }) => `repeat(auto-fit, minmax(${minWidth}, 1fr))`};
	width: 100%;
	margin-top: 24px;
`
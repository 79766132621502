import * as React from "react"
import { Section, Grid } from '../styled';
import PageTitle from '../../../common/page-title';
import Somersby from '../../../images/somersby.svg';
import Loreal from '../../../images/loreal.svg';
import Asahi from '../../../images/asahi.svg';
import SquealingPig from '../../../images/squealingpig.svg';
import Kellogs from '../../../images/kellogs.svg';
import Subway from '../../../images/subway.svg';
import Maybelline from '../../../images/maybelline.svg';
import Coopers from '../../../images/coopers.svg';
import Carlsberg from '../../../images/carlsberg.svg';
import NHP from '../../../images/nhp.svg';
import uniqueId from "lodash/uniqueId";
import Flex from '../../../common/flex';
import styled from "@emotion/styled";

const clients = [<Asahi key={uniqueId() + 'clients'} />, <Kellogs key={uniqueId() + 'clients'} />, <Loreal key={uniqueId() + 'clients'} />, <Carlsberg key={uniqueId() + 'clients'} />, <Somersby key={uniqueId() + 'clients'} />, <Coopers key={uniqueId() + 'clients'} />, <NHP key={uniqueId() + 'clients'} />, <Maybelline key={uniqueId() + 'clients'} />, <Subway key={uniqueId() + 'clients'} />, <SquealingPig key={uniqueId() + 'clients'} />];

const LogoWrapper = styled(Flex)`
	height: 100px;
`

export default function Clients() {
	return (
		<Section id="clients" direction="column" fullHeight align="flex-start" justify="center">
			<PageTitle theme="orange" superText="We work with some amazing">
				Brands.
			</PageTitle>
			<Grid>
				{clients.map((client, index) => 
					<LogoWrapper key={'client' + index} align="center" justify="center" fullWidth>
						<div 
							data-sal="slide-up"
							data-sal-delay={(index + 1) * 100}
							data-sal-easing="ease-in-out"
							data-sal-duration="500"
						>
							{client}
						</div>
					</LogoWrapper>
				)}
			</Grid>
		</Section>
	)
}
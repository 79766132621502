import * as React from 'react';
import { Section } from '../styled';
import PageTitle from '../../../common/page-title';
import Flex from '../../../common/flex';
import styled from '@emotion/styled';
import InstagramIcon from '../../../images/insta.svg';
import FacebookIcon from '../../../images/fb.svg';
import LinkedinIcon from '../../../images/linkedin.svg';
import { Formik } from 'formik';
import palette from '../../../common/palette';
import spacing, { px } from '../../../common/spacing';
import { respondTo } from '../../../common/dimensions';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
const Footer = styled.footer`
	position: absolute;
	bottom: 36px;
	left: 0;
	right: 0;
	text-align: center;
	max-width: 60%;
	margin: 0 auto;

	.subtle.links {
		display: grid;
		grid-auto-flow: column;
		grid-auto-columns: auto;
		grid-gap: 10px;
		margin: 0;

		a {
			color: #bea185;
		}
	}
`;

const Form = styled.form`
	padding-bottom: 100px;
	width: 100%;
	input,
	textarea {
		background: transparent;
		border-top: 0;
		border-left: 0;
		border-right: 0;
		color: ${palette.midnight.m1};
		border-color: ${palette.midnight.m1};
		::placeholder {
			color: ${palette.midnight.m1};
			opacity: 1;
			font-size: 16px;
		}
		resize: none;
		width: 100% !important;
		outline: none;
		padding: ${px(spacing.smallPlus)} 0;
		border-radius: 0;
		border-width: 1px;
		border-bottom-style: solid;
	}
`;
const FormField = styled.div`
	width: 100%;
	& > * {
		width: 100%;
	}
	input,
	textarea {
		border-color: ${({ hasError }) => (hasError ? palette.red.r1 : palette.midnight.m1)};
	}
`;

const SubmitButton = styled.button`
	background: ${({ isLoading }) => (isLoading ? palette.sunrise.s3 : palette.sunrise.s1)};
	padding: ${px(spacing.smallPlus)} ${px(spacing.smallPlus)};
	color: #ffffff;
	border-radius: 60px;
	width: 170px;
	font-size: 16px;
	font-weight: 500;
`;

const ErrorMessage = styled.div`
	color: ${palette.red.r1};
	margin-top: ${px(spacing.small)};
	font-size: 14px;
`;

const Socials = styled.a`
	width: 24px;
	text-align: center;
	svg {
		overflow: visible;
	}
`;

const Grid = styled.div`
	display: grid;
	gap: ${spacing.xLarge}px;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	width: 100%;
	margin-top: 24px;
	${respondTo.smOnly} {
		grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	}
`;

const initialState = {
	email: '',
	name: '',
	message: '',
	// recaptcha: '',
};

const url = 'https://3dlf1w546k.execute-api.us-east-1.amazonaws.com/production/contact-us';

function Contact() {
	const [token, setToken] = React.useState('');
	const { executeRecaptcha } = useGoogleReCaptcha();

	const handleFormikSubmit = async (
		data,
		{ setSubmitting, resetForm, setErrors, setStatus, setFieldError },
	) => {
		console.log({ data, token });
		if (!token.length) {
			setFieldError('generic', 'There has been an error validating with ReCaptcha');
			setSubmitting(false);
		} else {
			setSubmitting(true);
			const response = await fetch(url, {
				method: 'POST',
				body: JSON.stringify(data),
			});
			const responseData = await response.json();
			console.log({ responseData });

			setSubmitting(false);

			if (response.ok) {
				resetForm({ values: initialState });

				setStatus({ submitted: true });
			} else {
				setErrors({
					generic: 'We could not submit your form, please try again',
				});
			}
		}
	};

	React.useEffect(() => {
		console.log('Getting Google recaptcha...');
		(async () => {
			if (!executeRecaptcha) {
				console.log('Execute recaptcha not yet available');
				return;
			}
			(async () => {
				const token = await executeRecaptcha('signup');
				console.log('Google recaptcha set');
				setToken(token);
			})();
		})();
	}, [executeRecaptcha]);

	return (
		<Section id="contact" direction="column" fullHeight align="flex-start" justify="center">
			<Formik
				initialValues={initialState}
				validate={values => {
					const errors = {};
					if (!values.email) {
						errors.email = 'This field is required';
					} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
						errors.email = 'Please provide a valid email';
					}
					if (!values.name) {
						errors.name = 'This field is required';
					}
					if (!values.message) {
						errors.message = 'This field is required';
					}
					// if (!values.recaptcha) {
					// 	errors.recaptcha = 'This field is required';
					// }
					return errors;
				}}
				onSubmit={handleFormikSubmit}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					isSubmitting,
					status,
				}) => (
					<Form onSubmit={handleSubmit}>
						<PageTitle
							inverted={false}
							superText={
								status && status.submitted
									? 'Your message has been sent'
									: 'If you want to find out more'
							}
							seperator={false}
						>
							{status && status.submitted ? 'Thanks.' : 'Get in touch.'}
						</PageTitle>
						{status && status.submitted ? null : (
							<Flex direction="column" fullWidth childGap="xLarge" align="flex-start">
								<Grid>
									<FormField
										hasError={errors.name && touched.name && errors.name}
									>
										<input
											type="name"
											name="name"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.name}
											placeholder="Name"
										/>
										<ErrorMessage>
											{errors.name && touched.name && errors.name}
										</ErrorMessage>
									</FormField>
									<FormField
										hasError={errors.email && touched.email && errors.email}
									>
										<input
											type="email"
											name="email"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.email}
											placeholder="Email"
										/>
										<ErrorMessage>
											{errors.email && touched.email && errors.email}
										</ErrorMessage>
									</FormField>
								</Grid>
								<FormField
									hasError={errors.message && touched.message && errors.message}
								>
									<textarea
										type="message"
										name="message"
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.message}
										placeholder="Message"
										rows="1"
									/>
									<ErrorMessage>
										{errors.message && touched.message && errors.message}
									</ErrorMessage>
								</FormField>
								<SubmitButton
									type="submit"
									disabled={isSubmitting}
									isLoading={isSubmitting}
								>
									{isSubmitting ? 'Sending...' : 'Send'}
								</SubmitButton>
								{errors.generic ? (
									<ErrorMessage>{errors.generic}</ErrorMessage>
								) : null}
							</Flex>
						)}
					</Form>
				)}
			</Formik>
			<Footer>
				<Flex direction="column" childGap="small">
					<Flex direction="row" childGap="medium" align="center" justify="center">
						<Socials
							rel="noopener noreferrer"
							target="_blank"
							href="https://www.instagram.com/KollwitzOwen/"
						>
							<InstagramIcon />
						</Socials>
						<Socials
							rel="noopener noreferrer"
							target="_blank"
							href="https://www.facebook.com/KollwitzOwen/"
						>
							<FacebookIcon />
						</Socials>
						<Socials
							rel="noopener noreferrer"
							target="_blank"
							href="https://www.linkedin.com/company/kollwitzowen/"
						>
							<LinkedinIcon />
						</Socials>
					</Flex>
					<span className="subtle">
						Copyright&nbsp;&copy;&nbsp;{new Date().getFullYear()}
						&nbsp;KollwitzOwen&nbsp;PTY&nbsp;LTD. All&nbsp;rights&nbsp;reserved.
						ABN&nbsp;24&nbsp;638&nbsp;565&nbsp;474
					</span>
					<span className="subtle links">
						<a
							rel="noopener noreferrer"
							target="_blank"
							href="/KollwitzOwen_DataProtectionPolicy_2023.pdf"
						>
							Data protection policy
						</a>
						<a rel="noopener noreferrer" target="_blank" href="/sitemap-index.xml">
							Sitemap
						</a>
					</span>
				</Flex>
			</Footer>
		</Section>
	);
}

export default function ContactWithRecaptcha(props) {
	return (
		<GoogleReCaptchaProvider
			scriptProps={{ async: true, defer: true }}
			reCaptchaKey={process.env.GATSBY_GOOGLE_RECAPTCHA}
		>
			<Contact {...props} />
		</GoogleReCaptchaProvider>
	);
}

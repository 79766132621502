import * as React from 'react'
import Seo from '../components/seo'
import * as PageSection from '../components/page-sections'
import { ElementChange } from '../common/element-change'
import Logo from '../images/logo.svg'
import LightLogo from '../images/logo_light.svg'
import OrangeLogo from '../images/logo_orange.svg'
import uniqueId from 'lodash/uniqueId'

const Homepage = () => {
	return (
		<>
			<Seo title="KO Group | Promotions made easy" />
			<ElementChange
				itemsToReplace={{ normal: <Logo />, orange: <OrangeLogo /> }}
				replacingItems={{ light: <LightLogo /> }}
				sections={[
					<PageSection.Main key={uniqueId() + 'pageKey'} />,
					<PageSection.Simple key={uniqueId() + 'pageKey'} />,
					<PageSection.Clients key={uniqueId() + 'pageKey'} />,
					<PageSection.Services key={uniqueId() + 'pageKey'} />,
					<PageSection.Work key={uniqueId() + 'pageKey'} />,
					<PageSection.Contact key={uniqueId() + 'pageKey'} />,
				]}
			/>
		</>
	)
}
export default Homepage

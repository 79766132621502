import * as React from "react"
import styled from '@emotion/styled';
import palette from "../palette";

const Content = styled.div`
    color: ${props => props.inverted ? '#FFFFFF' : palette.midnight.m1};
    margin: 0;
    font-size: 12pt;
    text-align: left;
    z-index: 1;
`

export default function PageContent({ children, inverted = true, animation = true }) {
	return (
		<Content inverted={inverted}>
			{
				animation
					?
					<div 
						data-sal="slide-up"
						data-sal-delay="800"
						data-sal-easing="ease-in-out"
						data-sal-duration="500"
					>
						{children}
					</div>
					:children
			}
			
		</Content>
	)
}
import * as React from "react"
import { Section } from '../styled';
import PageTitle from '../../../common/page-title';
import Flex from '../../../common/flex';
import styled from "@emotion/styled";
import palette, { background } from "../../../common/palette";
import { respondTo } from "../../../common/dimensions";
import content from './content';
import DiscoverIcon from '../../../images/discover.svg';
import { Link } from "gatsby"

const Grid = styled.div`
	display: grid;
	gap: 0;
	grid-template-columns: 1fr 1fr;
	width: 100%;
	margin-top: 24px;
	div:nth-of-type(4n + 3),
	div:nth-of-type(4n + 4) {
		flex-direction: row-reverse;
	}
	${respondTo.mdDown} {
		grid-template-columns: 1fr;
		div:nth-of-type(4n + 3),
		div:nth-of-type(4n + 4) {
			flex-direction: row;
		}
		div:nth-of-type(even) {
			flex-direction: row-reverse;
		}
	}
`
const backgrounds = [
	palette.midnight.m1,
	background.light,
	palette.midnight.m2,
	palette.midnight.m1,
	background.light,
	palette.midnight.m2,
	palette.midnight.m1,
	background.light
];

const Box = styled.div`
	background: ${props => props.background};
	min-height: 150px;
	min-width: 150px;
	width: 100%;
	aspect-ratio: 1;
	color: ${props => props.background === background.light ? palette.midnight.m1 : '#FFFFFF'};
	${respondTo.smOnly} {
		min-height: 100px;
		min-width: 100px;
	}
`;

const DiscoverButton = styled(Link)`
	padding: 0;
	color: inherit;
`

const TextWrapper = styled(Flex)`
	padding: 24px;
	max-width: 80%;
	h3 {
		margin-bottom: 8px;
		font-family: 'minion-pro', Calibri, 'Trebuchet MS', sans-serif;
		font-size: 30px;
		font-weight: 700;
	}
	svg {
		height: 20px;
	}
	a {
		text-decoration: none!important;
	}
	${respondTo.smOnly} {
		max-width: 100%;
		padding: 12px;
		h3 {
			font-size: 22px;
		}
	}
`

const CaseStudy = ({background, image, label, id}) => {
	return (
		<Flex align="stretch">
			<Box>
				{image}
			</Box>
			<Box background={background}>
				<TextWrapper direction="column" fullHeight align="flex-start" justify="flex-end">
					<h3>{label}</h3>
					<DiscoverButton to={`/${id}`}>
						<DiscoverIcon />
					</DiscoverButton>
				</TextWrapper>
			</Box>
		</Flex>
	)
}

export default function Work() {
	return (
		<Section id="work" direction="column" fullHeight align="flex-start" justify="center">
			<PageTitle theme="orange" superText="Just a taste of some of our">
				Work.
			</PageTitle>
			<Grid gap="0">
				{content.map((caseStudy, index) => 
					<CaseStudy 
						key={index + 'case'} 
						background={backgrounds[index]} 
						{...caseStudy} 
					/>
				)}
			</Grid>
		</Section>
	)
}
import * as React from 'react'
import uniqueId from 'lodash/uniqueId'
import styled from '@emotion/styled'
import { Section } from '../styled'
import PageTitle from '../../../common/page-title'
import PageContent from '../../../common/page-content'
import GovernmentCertified from '../../../images/government.svg'
import Occular from '../../../images/occular.svg'
import LightningFast from '../../../images/lightning.svg'
import Tailored from '../../../images/tailored.svg'
import Functional from '../../../images/functional.svg'
import Flex from '../../../common/flex'
import { respondTo } from '../../../common/dimensions'
import spacing from '../../../common/spacing'

const Link = styled.a`
	color: white;
`

const services = [
	{
		key: uniqueId() + 'service',
		title: 'Winner selection',
		paragraph: (
			<p>
				Whether it’s an instant win, game of skill or lottery style prize draw, we use a
				state of the art Quantum Random Number Generator to select the winners legally. And
				our system is compliant in Australia and New Zealand.{' '}
				<Link
					target="_blank"
					rel="noreferrer noopener"
					href="https://s3.ap-southeast-2.amazonaws.com/kollwitzowen.com.au/Approval+of+Electronic+Drawing+system++1316.pdf"
				>
					Download a copy of our certification.
				</Link>
			</p>
		),
		stamp: <GovernmentCertified />,
	},
	{
		key: uniqueId() + 'service',
		title: 'Entry validation',
		paragraph: (
			<p>
				When it comes to high volume promotions such a Gift with Purchase (GWP) promotions,
				validating eligible entrants successfully and in a timely manner is crucial. That’s
				why our automated validation system is an invaluable feature to utilise.
			</p>
		),
		stamp: <Occular />,
	},
	{
		key: uniqueId() + 'service',
		title: 'Reporting',
		paragraph: (
			<p>
				Collating data from your promotion is critical. So we built KOHub, which allows you
				to monitor all your past and present promotions. Watch as data flows in during a
				live promo and compare past reports with ease. All easily exportable, and secure.
			</p>
		),
		stamp: <LightningFast />,
	},
	{
		key: uniqueId() + 'service',
		title: 'Microsites',
		paragraph: (
			<p>
				Our competition sites vary from simple, template-based designs, all the way to
				custom gamified experiences. However, even the most basic promotions should be
				consistent with your brand guidelines, and we guarantee a seamless integration with
				your look &amp; feel.
			</p>
		),
		stamp: <Tailored />,
	},
	{
		key: uniqueId() + 'service',
		title: 'Custom development',
		paragraph: (
			<p>
				Although we live &amp; breathe promotions, our offering doesn’t end there. Our team
				of full-stack developers can design and build just about anything to solve your
				digital requirements, including eCommerce platforms and sales/inventory projection
				systems.
			</p>
		),
		stamp: <Functional />,
	},
]

const ServiceBlockWrapper = styled.div`
	display: grid;
	gap: ${spacing.xxLarge}px;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	width: 100%;
	margin-top: 24px;
	${respondTo.smOnly} {
		grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	}
`

const ServiceBlock = ({ children, title, stamp, index }) => {
	return (
		<Flex direction="column" fullWidth align="flex-start" justify="flex-start">
			<div
				data-sal="slide-up"
				data-sal-delay={(index + 1) * 100}
				data-sal-easing="ease-in-out"
				data-sal-duration="300"
			>
				<h4>{title}</h4>
				{children}
				<br />
				{stamp}
			</div>
		</Flex>
	)
}

export default function Services() {
	return (
		<Section id="services" direction="column" fullHeight align="flex-start" justify="center">
			<PageTitle superText="We design &amp; build">Promotions.</PageTitle>
			<PageContent animation={false}>
				<ServiceBlockWrapper minWidth="300px" gap="xxLarge">
					{services.map(({ paragraph, ...rest }, index) => (
						<ServiceBlock key={rest.key} index={index} {...rest}>
							{paragraph}
						</ServiceBlock>
					))}
				</ServiceBlockWrapper>
			</PageContent>
		</Section>
	)
}
